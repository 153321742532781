import React, { useState } from 'react'
import { FiEye, FiEyeOff } from 'react-icons/fi'
import 'styles/components/generic/passwordInputField.scss'

function PasswordInputField({
  name,
  label,
  value,
  handleChange,
  placeholder,
}: {
  name: string
  label?: string
  value?: string | number
  handleChange: React.ChangeEventHandler<HTMLInputElement>
  placeholder?: string
}) {
  const [showPassword, setShowPassword] = useState(false)

  return (
    <div className='passwordInputField'>
      {label && (
        <label htmlFor={name} className='label'>
          {label}
        </label>
      )}
      <div className='fieldBox'>
        <input
          id={name}
          name={name}
          type={showPassword ? 'text' : 'password'}
          className='input'
          value={value}
          onChange={handleChange}
          placeholder={placeholder}
        />
        <button
          type='button'
          className='icon'
          onClick={() => setShowPassword(!showPassword)}
          aria-label={showPassword ? 'Hide password' : 'Show password'}
        >
          {showPassword ? <FiEyeOff /> : <FiEye />}
        </button>
      </div>
    </div>
  )
}

export default PasswordInputField
