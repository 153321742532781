import React, { useState } from 'react'
import styles from './Subscription.module.scss'
import { Tooltip } from '@mui/material'
import { subscriptionsController } from 'controllers'
import { useSnackbar } from 'notistack'
import { FiEdit2 } from 'react-icons/fi'
import { EditSubscription } from './EditSubscription'
import { ISubscription } from 'interfaces/subscriptionsInterface'

interface SubscriptionProps {
  subscription: ISubscription
  getSubscriptions: () => void
}

export const Subscription = ({ subscription, getSubscriptions }: SubscriptionProps) => {
  const { enqueueSnackbar } = useSnackbar()
  const [editMode, setEditMode] = useState(false)

  const deleteSubscription = async () => {
    try {
      await subscriptionsController.deleteSubscription(subscription.uuid)
      getSubscriptions()
      enqueueSnackbar('Subscription deleted', { variant: 'success' })
    } catch (error: any) {
      enqueueSnackbar(error.message, { variant: 'error' })
    }
  }

  const saveSubscription = async (subscriptionData: ISubscription) => {
    try {
      await subscriptionsController.updateSubscription({
        ...subscriptionData,
        currency: subscriptionData.currency.value,
      })
      await getSubscriptions()
      setEditMode(false)
      enqueueSnackbar('Subscription updated', { variant: 'success' })
    } catch (error: any) {
      enqueueSnackbar(error.message, { variant: 'error' })
    }
  }

  const formatInterval = (interval: string) => {
    switch (interval.toLowerCase()) {
      case 'monthly':
        return 'mo'
      case 'yearly':
        return 'yr'
      case 'weekly':
        return 'wk'
      default:
        return interval
    }
  }

  return (
    <div className={styles.subscription}>
      {editMode ? (
        <EditSubscription
          subscriptionToEdit={subscription}
          deleteSubscription={deleteSubscription}
          saveSubscription={saveSubscription}
          cancelEdit={() => setEditMode(false)}
        />
      ) : (
        <>
          <div className={styles.subscriptionWrap}>
            <div className={styles.block}>
              <img 
                src={subscription?.app?.imageUrl} 
                className={styles.appIcon} 
                alt={subscription?.app?.title || 'App Icon'} 
              />
              <p>{subscription?.app?.title}</p>
            </div>
            {subscription.cost && (
              <p className={styles.price}>
                {subscription.currency} {subscription.cost} / {formatInterval(subscription.interval)}
              </p>
            )}
          </div>
          
          <Tooltip title="Edit Subscription" placement="top">
            <div className={styles.editButton}>
              <button onClick={() => setEditMode(true)} aria-label="Edit subscription">
                <FiEdit2 />
              </button>
            </div>
          </Tooltip>

          {subscription.note && (
            <p className={styles.note}>{subscription.note}</p>
          )}
        </>
      )}
    </div>
  )
}
