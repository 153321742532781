import React, { useState } from 'react'
import { TextField } from '@material-ui/core'
import { InputField, SelectorField } from 'components/generic'
import { LabelValue } from 'interfaces/generic'
import styles from './EditSubscription.module.scss'
import { currencyOptions } from 'utils/appConsts'
import {
  ISubscription,
  SubscriptionInterval,
  SubscriptionPlan,
} from 'interfaces/subscriptionsInterface'

interface AddSubscriptionProps {
  subscriptionToEdit: ISubscription
  cancelEdit: () => void
  deleteSubscription: () => void
  saveSubscription: (subscription: ISubscription) => void
}

const billingIntervals = {
  month: { value: 'month', label: 'Monthly' },
  year: { value: 'year', label: 'Yearly' },
  week: { value: 'week', label: 'Weekly' },
}

export const EditSubscription = ({
  subscriptionToEdit,
  deleteSubscription,
  saveSubscription,
  cancelEdit,
}: AddSubscriptionProps) => {
  const [subscriptionData, setSubscriptionData] = useState<ISubscription>({
    ...subscriptionToEdit,
    appId: subscriptionToEdit.app.uuid,
    currency: {
      label: subscriptionToEdit.currency.label || 'USD',
      value: subscriptionToEdit.currency.value || 'USD',
    },
  })

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>,
  ) => {
    setSubscriptionData((s) => ({ ...s, [e.target.name]: e.target.value }))
  }

  return (
    <div className={styles.editSubscription}>
      <div className={styles.header}>
        <img 
          src={subscriptionToEdit.app.imageUrl} 
          alt={subscriptionToEdit.app.title} 
          className={styles.appIcon}
        />
        <h3 className={styles.appTitle}>{subscriptionToEdit.app.title}</h3>
      </div>

      {subscriptionData.plan === SubscriptionPlan.Paid && (
        <div className={styles.formSection}>
          <h4 className={styles.sectionTitle}>Billing Details</h4>
          <div className={styles.billingInputs}>
            <InputField
              name="cost"
              label="Cost"
              value={subscriptionData.cost}
              handleChange={handleChange}
              placeholder="0.00"
              type="currency"
            />
            <SelectorField
              name="currency"
              label="Currency"
              options={currencyOptions}
              value={subscriptionData.currency}
              handleChange={(e: LabelValue) => setSubscriptionData((s) => ({ ...s, currency: e }))}
            />
            <SelectorField
              name="billing-frequency"
              label="Billing Frequency"
              options={Object.values(billingIntervals)}
              value={billingIntervals[subscriptionData.interval]}
              handleChange={(e: LabelValue) =>
                setSubscriptionData({
                  ...subscriptionData,
                  interval: e.value as SubscriptionInterval,
                })
              }
            />
          </div>
        </div>
      )}

      <div className={styles.formSection}>
        <h4 className={styles.sectionTitle}>Additional Information</h4>
        <TextField
          label="Subscription Note"
          placeholder="Add a note about this subscription (optional)"
          variant="outlined"
          fullWidth
          multiline
          rows={3}
          value={subscriptionData.note}
          onChange={(e) => setSubscriptionData((s) => ({ ...s, note: e.target.value }))}
          inputProps={{
            maxLength: 100,
          }}
          className={styles.noteField}
        />
      </div>

      <div className={styles.buttonGroup}>
        <div>
          <button 
            onClick={deleteSubscription}
            className={styles.deleteButton}
          >
            Delete
          </button>
        </div>
        <div>
          <button 
            onClick={cancelEdit}
            className={styles.cancelButton}
          >
            Cancel
          </button>
          <button 
            onClick={() => saveSubscription(subscriptionData)}
            className={styles.saveButton}
          >
            Save Changes
          </button>
        </div>
      </div>
    </div>
  )
}
