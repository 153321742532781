import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { useSnackbar } from 'notistack'
import 'styles/pages/loginPage.scss'
import { InputField, PasswordInputField } from 'components/generic'
import { adminController } from 'controllers'
import { adminInterface } from 'interfaces'
import { errorHelper } from 'helpers'

function LoginPage({
  isLoggedIn,
  setIsLoggedIn,
}: {
  isLoggedIn: boolean
  setIsLoggedIn: React.Dispatch<React.SetStateAction<boolean>>
}) {
  const navigate = useNavigate()
  const [loginData, setLoginData] = useState<adminInterface.IAdminLogin>({
    email: '',
    password: '',
  })
  const { enqueueSnackbar } = useSnackbar()

  useEffect(() => {
    if (isLoggedIn) navigate('/')
  }, [isLoggedIn])

  function handleChange(e: React.ChangeEvent<HTMLInputElement>) {
    const { name, value } = e.target
    setLoginData((prev) => ({
      ...prev,
      [name]: value,
    }))
  }

  async function handleLogin() {
    try {
      if (!loginData.email || !loginData.password) {
        throw new errorHelper.CodeError('Please enter email and password', 400)
      }
      
      // Attempt login
      const response = await adminController.login(loginData)
      
      // Only set logged in state if we got a valid response with auth token
      if (response && response.accessToken) {
        setIsLoggedIn(true)
        enqueueSnackbar('Logged in successfully', { variant: 'success' })
        navigate('/')
      } else {
        throw new errorHelper.CodeError('Invalid login response', 401)
      }
    } catch (error: any) {
      const errorMessage = error.message || 'Login failed'
      enqueueSnackbar(errorMessage, { variant: 'error' })
      // Ensure we're marked as logged out on error
      setIsLoggedIn(false)
    }
  }

  return (
    <div className='loginPage'>
      <div className='loginSide'>
        <div className='loginContent'>
          <div className='formContainer'>
            <h2 className='welcomeText'>Welcome back</h2>
            <p className='subtitle'>Enter your credentials to access your account</p>
            <form
              className='loginForm'
              onSubmit={(e) => {
                e.preventDefault()
                handleLogin()
              }}
            >
              <div className='inputWrapper'>
                <InputField
                  name='email'
                  label='Email'
                  value={loginData?.email}
                  handleChange={(e) => handleChange(e)}
                  placeholder='Enter your email'
                />
                <PasswordInputField
                  name='password'
                  label='Password'
                  value={loginData?.password}
                  handleChange={(e) => handleChange(e)}
                  placeholder='Enter your password'
                />
              </div>
              <button type='submit' className='loginButton'>
                <span>Sign In</span>
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  )
}

export default LoginPage
